<template>
  <body-with-header class="h-100" title="برنامه غذایی" :ads="true">
    <CRow class="diet-page position-absolute body-wrapper">
      <CRow v-if="!loading && rowData.length === 0" class="flex-center w-100">
        <no-data
          :show="!loading && rowData.length === 0"
          message="برنامه غذایی برای زمین یافت نشد."
          class="emptyTitle"
        />
      </CRow>
      <CRow v-else class="w-100">
        <CCol md="3" class="diet-wrapper">
          <vue-element-loading
            :active="loading"
            :text="loadingText"
            spinner="bar-fade-scale"
            color="var(--secondary)"
          />
          <div class="vertical-g-20">
            <image-card
              v-for="(item, index) of rowData"
              :active="dietPlanImage === item.image"
              :key="index"
              :loading="loadingInfo && selectedRow === item.id"
              :image="getSrc(item.banner, 'Banner')"
              @showDetail="showModal(item.image, 'detail')"
            >
              <h6 class="mb-1">{{ item.title }}</h6>
              <p class="small" v-if="item.description !== 'null'">
                {{ item.description }}
              </p>
            </image-card>
          </div>
        </CCol>

        <CCol col="9" class="main-card planInfoBox" v-if="subForm != null">
          <div id="itemDetailPreview">
            <vue-element-loading
              :active="loading"
              :text="loadingText"
              spinner="bar-fade-scale"
              color="var(--secondary)"
            />
            <component v-bind:is="subForm" :dietPlanImage="dietPlanImage" />
          </div>
        </CCol>
      </CRow>
    </CRow>
  </body-with-header>
</template>

<script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../constants/config";
import detail from "./detail.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Multiselect from "vue-multiselect";
import BodyWithHeader from "../../components/bodyWithHeader.vue";
import ImageCard from "../../components/imageCard.vue";
import NoData from "../../components/no-data.vue";

export default {
  name: "dietPlanList",
  props: ["dietImage"],
  components: {
    NoData,
    ImageCard,
    BodyWithHeader,
    vSelect,
    Multiselect,
    detail,
  },
  data() {
    return {
      rowData: null,
      loading: false,
      loadingText: "در حال دریافت اطلاعات...",
      dietPlanImage: "",
      subForm: null,
      searchList: null,
    };
  },
  watch: {
    searchList: function () {
      this.fetchData();
    },
  },
  methods: {
    ...mapActions("dietPlan", ["GetAllDietPlanUserFarms"]),
    getSrc(image, type) {
      return `${apiUrlRoot}/DietPlan/${type}/${image}`;
    },
    async fetchData(image = undefined) {
      this.loading = true;
      let result = await this.GetAllDietPlanUserFarms({});
      this.loading = false;
      if (result) {
        this.rowData = result;
        if (result.length > 0)
          this.showModal(image ?? result[0].image, "detail");
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات <br /><br />",
          type: "error",
        });
      }
    },
    showModal(image, componentName) {
      this.subForm = null;
      this.dietPlanImage = image;
      this.subForm = componentName;
    },
  },
  mounted() {
    if (this.dietImage) {
      this.dietPlanImage = this.dietImage;
    }
    this.fetchData(this.dietImage);
  },
};
</script>

<style scoped>
.diet-page {
  left: 0;
  right: 0;
}

.planInfoBox {
  overflow-y: auto;
}

.dietInfoBox {
  max-width: 45rem;
  margin: auto;
}

.emptyTitle {
  padding-top: 10%;
  color: #bbb;
  text-align: center;
}

.diet-wrapper {
  overflow: auto;
  top: 9vh;
  position: sticky !important;
}
</style>
